import { styled, Modal, Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ComponentProps, useEffect, useState } from "react";

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.customBackground.cards};
    border: 1px solid ${theme.palette.error.main};
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(2)};
  `,
);

type ErrorBoundaryPopupProps = {
  message: React.ReactNode;
  reloadTimer?: boolean;
  onClose?: () => void;
} & ComponentProps<typeof StyledBox>;

export default function ErrorBoundaryPopup(props: ErrorBoundaryPopupProps) {
  const { message, reloadTimer, onClose: onCloseProps, ...restProps } = props;
  const [errorPopup, setErrorPopup] = useState<boolean>(true);
  const [reloadSecRem, setReloadSecRem] = useState<number>(5);

  const onModalClose = () => {
    setErrorPopup(false);
    onCloseProps?.();
  };

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;
    if (reloadTimer) {
      interval = setInterval(() => setReloadSecRem((prev) => prev - 1), 1000);
    }
    return () => {
      if (interval) clearTimeout(interval);
    };
  }, [reloadTimer]);

  useEffect(() => {
    if (reloadTimer && reloadSecRem === 0) {
      window.location.reload();
    }
  }, [reloadSecRem, reloadTimer]);

  return (
    <StyledModal open={Boolean(errorPopup)} onClose={onModalClose}>
      <StyledBox alignItems="center" height="max-content" margin="auto" pb={2} px={2} width={455} {...restProps}>
        <ErrorOutlineIcon sx={{ width: 88, height: 88 }} color="error" />
        <Typography variant="body1" textAlign="center" my={2}>
          {message}
          {reloadTimer && `The Page will reload in ${reloadSecRem}`}
        </Typography>
      </StyledBox>
    </StyledModal>
  );
}
