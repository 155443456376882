export const USER_RESTRICTIONS_FRAGMENT = `
  fragment UserRestrictionsFragment on UserQueryType {
      restrictions {
        toolEnum
        rOILimit
        maxResult
        sportbookLimit
        leagueLimit
      }
  }
`;

export type UserRestrictionsFragment = {
  restrictions: PickUserRestrictionType[];
};
export const GET_USER_RESTRICTIONS = `
  query UserRestrictions {
    user {
      ...UserRestrictionsFragment
    }
  }
  ${USER_RESTRICTIONS_FRAGMENT}
`;

export type GetUserRestrictions = {
  user: UserRestrictionsFragment;
};

export enum ToolEnum {
  ARBITRAGE = "ARBITRAGE",
  MIDDLE = "MIDDLE",
  FREE_BET_CONVERTER = "FREE_BET_CONVERTER",
  LOW_HOLD = "LOW_HOLD",
  EV = "EV",
  ODDS_SCREEN = "ODDS_SCREEN",
}

export const toolDisplayNames = {
  [ToolEnum.EV]: "Positive EV",
  [ToolEnum.ARBITRAGE]: "Arbitrage",
  [ToolEnum.MIDDLE]: "Middle",
  [ToolEnum.LOW_HOLD]: "Low Hold",
  [ToolEnum.FREE_BET_CONVERTER]: "Free Bet Converter",
  [ToolEnum.ODDS_SCREEN]: "Odds Screen",
};

export const toolOrder = [
  ToolEnum.ODDS_SCREEN,
  ToolEnum.ARBITRAGE,
  ToolEnum.EV,
  ToolEnum.MIDDLE,
  ToolEnum.FREE_BET_CONVERTER,
  ToolEnum.LOW_HOLD,
];

export type UserRestrictionType = {
  rOILimit: number | null;
  maxResult: number | null;
  sportbookLimit: number | null;
  leagueLimit: number | null;
};

export type PickUserRestrictionType = {
  toolEnum: ToolEnum;
} & UserRestrictionType;
