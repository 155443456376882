import { useTheme } from "@/theme/ThemeContext";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import React from "react";

type LogoutDialogProps = {
  isOpenLogoutDialog: boolean;
  setOpenLogoutDialog: (val: boolean) => void;
  logoutUser: () => void;
};

function LogoutDialog(props: LogoutDialogProps) {
  const { isOpenLogoutDialog, setOpenLogoutDialog, logoutUser } = props;
  const theme = useTheme();
  const handleClose = () => {
    setOpenLogoutDialog(false);
  };

  return (
    <Dialog
      open={isOpenLogoutDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { backgroundColor: theme.palette.customBackground.cards } }}
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to Logout?</DialogTitle>
      <DialogActions>
        <Button size="large" color="inherit" onClick={logoutUser}>
          Yes
        </Button>
        <Button size="large" color="inherit" onClick={handleClose} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LogoutDialog;
