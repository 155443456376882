import { ThemeProvider as MuiThemeProvider, Theme } from "@mui/material/styles";
import Cookies from "js-cookie";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useEventCallback } from "@mui/material";
import { createTheme } from "./theme";
import { DEFAULT_PALETTE_TYPE, PaletteType } from "./common";

export const THEME_COOKIE_NAME = "theme";

export type ThemeContextValue = Theme & {
  setThemePreferences: (themePalette: PaletteType) => void;
};

const ThemeContext = React.createContext<ThemeContextValue>(undefined!);
export default ThemeContext;

export function useTheme() {
  return useContext(ThemeContext);
}

type ThemeProviderProps = {
  children: React.ReactNode;
  serverCookie: string | undefined;
};

export function ThemeProvider(props: ThemeProviderProps) {
  const { serverCookie, children } = props;
  const isMobile = useMediaQuery("(max-width:900px)");

  const [theme, setTheme] = useState(() => {
    let cookie: string | undefined;
    if (typeof document === "undefined") {
      cookie = serverCookie;
    } else {
      cookie = Cookies.get(THEME_COOKIE_NAME);
    }
    const paletteType: PaletteType = (cookie as PaletteType) || DEFAULT_PALETTE_TYPE;

    // NOTE: brave restrives js cookies to 7 days of expiration time.
    // src: https://community.brave.com/t/cookie-max-age-too-short/466612/9
    // if brave user visits website once per 7 days, their preferences will be
    // remembered.
    if (typeof document !== "undefined" && paletteType !== DEFAULT_PALETTE_TYPE) {
      Cookies.set(THEME_COOKIE_NAME, paletteType, { expires: 365 });
    }

    return createTheme(paletteType, isMobile);
  });

  useEffect(() => {
    let cookie: string | undefined;
    if (typeof document === "undefined") {
      cookie = serverCookie;
    } else {
      cookie = Cookies.get(THEME_COOKIE_NAME);
    }
    const paletteType: PaletteType = (cookie as PaletteType) || DEFAULT_PALETTE_TYPE;
    setTheme(createTheme(paletteType, isMobile));
  }, [isMobile, serverCookie]);

  // update the metaTag
  useEffect(() => {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      metaTag.setAttribute("content", theme.palette.primary.main);
    }
  }, [theme.palette.primary.main]);

  const setThemePreferences = useEventCallback((themePalette: PaletteType) => {
    setTheme((prev) => {
      if (prev.palette.mode === themePalette) return prev;

      if (themePalette !== DEFAULT_PALETTE_TYPE) {
        // set only if not default
        Cookies.set(THEME_COOKIE_NAME, themePalette, {
          expires: 365,
        });
      } else {
        // otherwise remove
        Cookies.remove(THEME_COOKIE_NAME);
      }

      return createTheme(themePalette, isMobile);
    });
  });

  const providerValue: ThemeContextValue = useMemo(
    () => ({
      ...theme,
      setThemePreferences,
    }),
    [theme, setThemePreferences],
  );

  return (
    <ThemeContext.Provider value={providerValue}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}
