import { createDeferredPromise } from "@/utis/deferredPromise";

export const DEV_API_HOST = "https://apibeta.picktheodds.app/graphql";
export const PROD_API_HOST = "https://api.picktheodds.app/graphql";

export const REST_API_PREFIX = "api/v1";

// NOTE: those setters and getters will not work in a nodejs runtime because
// it'll be responsible for serving requests to multiple users, but edge is
// stateless.

const gApiHost =
  process.env.NEXT_PUBLIC_GRAPH_QL_ENDPOINT || (process.env.NODE_ENV === "production" ? PROD_API_HOST : DEV_API_HOST);
export function gGetApiHost() {
  return gApiHost;
}

let gcApiToken: string | undefined;
const gcApiTokenPromise = createDeferredPromise();

export function gcSetApiToken(newApiToken: string) {
  gcApiToken = newApiToken;
  gcApiTokenPromise.resolve();
}

export function gcGetApiToken() {
  if (!gcApiToken) {
    throw new Error("api token was not set");
  }
  return gcApiToken;
}

export async function gcGetApiTokenAsync() {
  await gcApiTokenPromise.promise;
  return gcGetApiToken();
}

type Variables = Record<string, unknown>;

type FetchGraphqlRequest<V extends Variables = Variables> = {
  query: string;
  variables?: V;
};

export type FetchGraphqlResponse<D> = {
  data: D;
  errors?: Array<unknown>;
};

export type FetchGraphqlParams<V extends Variables = Variables> = FetchGraphqlRequest<V> & {
  apiToken: string;
};

export async function fetchGraphql<Data = unknown, V extends Variables = Variables>(
  params: FetchGraphqlParams<V>,
): Promise<FetchGraphqlResponse<Data>> {
  const { query, variables, apiToken } = params;

  const resp = await fetch(`${gGetApiHost()}/graphql`, {
    method: "POST",
    headers: {
      authorization: `bearer ${apiToken}`,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await resp.json();
  return json as FetchGraphqlResponse<Data>;
}
