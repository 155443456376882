import { fetchGraphql } from "@/fetchGraphql";
import useGetPathKey from "@/hooks/useGetPathKey";
import { KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { ToolEnum, GET_USER_RESTRICTIONS, GetUserRestrictions, PickUserRestrictionType } from "./common";

export const DEFAULT_RESTRICTION = {
  rOILimit: 2,
  maxResult: 2,
  sportbookLimit: 2,
  leagueLimit: 2,
};

function getToolEnumFromPageFilterKey(pathKey: KeyOfPages) {
  if (pathKey === "arbitrages") return ToolEnum.ARBITRAGE;
  if (pathKey === "middles") return ToolEnum.MIDDLE;
  if (pathKey === "freebet") return ToolEnum.FREE_BET_CONVERTER;
  if (pathKey === "lowhold") return ToolEnum.LOW_HOLD;
  if (pathKey === "expectedvalue") return ToolEnum.EV;
  return undefined;
}

export async function fetchUserRestriction(apiToken: string) {
  const resp = await fetchGraphql<GetUserRestrictions>({ query: GET_USER_RESTRICTIONS, apiToken });
  if (resp.errors?.length) {
    throw new Error("could not fetch user Restrictions");
  }
  return resp.data?.user.restrictions;
}

export function useGetCurrentPageRestriction(restrictions?: PickUserRestrictionType[] | null) {
  const pathKey = useGetPathKey();
  if (!restrictions) return DEFAULT_RESTRICTION;
  const toolEnum = getToolEnumFromPageFilterKey(pathKey);
  if (toolEnum === undefined) {
    return {
      rOILimit: null,
      maxResult: null,
      sportbookLimit: null,
      leagueLimit: null,
    };
  }
  return restrictions.find((restriction) => restriction.toolEnum === toolEnum) || DEFAULT_RESTRICTION;
}
