type Variables = Record<string, unknown>;

type FetchGraphqlRequest<V extends Variables = Variables> = {
  query: string;
  variables?: V;
};

export type FetchGraphqlResponse<D> = {
  data: D;
  errors?: Array<Error>;
};

export type FetchGraphqlParams<V extends Variables = Variables> = FetchGraphqlRequest<V> & {
  apiToken?: string;
};

export async function fetchGraphql<Data = unknown, V extends Variables = Variables>(
  params: FetchGraphqlParams<V>,
): Promise<FetchGraphqlResponse<Data>> {
  const { query, variables, apiToken } = params;

  try {
    const resp = await fetch(`${process.env.NEXT_PUBLIC_GRAPH_QL_ENDPOINT}`, {
      method: "POST",
      headers: {
        authorization: `bearer ${apiToken}`,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    const json = await resp.json();
    return json as FetchGraphqlResponse<Data>;
  } catch (err) {
    return {
      data: undefined,
      errors: [err],
    } as FetchGraphqlResponse<Data>;
  }
}
