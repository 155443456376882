//  From @hitagi/utis

export type DeferredPromise<T = void> = {
  resolve: (value: T | PromiseLike<T>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason?: any) => void;
  promise: Promise<T>;
  status?: PromiseSettledResult<void>["status"];
};

export function createDeferredPromise<T = void>(): DeferredPromise<T> {
  const deferredPromise: Partial<DeferredPromise<T>> = {};
  deferredPromise.promise = new Promise<T>((resolve, reject) => {
    deferredPromise.resolve = (...args) => {
      resolve(...args);
      deferredPromise.status = "fulfilled";
    };
    deferredPromise.reject = (...args) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(...args);
      deferredPromise.status = "rejected";
    };
  });
  return deferredPromise as DeferredPromise<T>;
}
