import React, { useCallback, useContext, useMemo, useState } from "react";
import ErrorBoundaryPopup from "@/components/ErrorBoundary/ErrorBoundaryPopup";
import LogoutDialog from "@/components/LogoutDialog";
import { gcSetApiToken } from "@/api/api";
import { UserType, refreshAnonymousToken, UserQueryType, logOut } from "./common";
import { errorMessage } from "./errorMessage";
import { PickUserRestrictionType, UserRestrictionType } from "./UserRestrictions/common";
import { useGetCurrentPageRestriction } from "./UserRestrictions/helper";

export type RefreshAnonymousTokenParams = {
  onRefreshed?: () => void;
};

export type UserContextValue = {
  isLoggedIn: boolean;
  data: UserType | null | undefined;
  restrictions: UserRestrictionType;
  updateUser: (newUser: UserType) => void;
  openLogoutDialog: () => void;
  refreshUserToken: (params?: RefreshAnonymousTokenParams) => Promise<string | null>;
};

const UserContext = React.createContext<UserContextValue>(undefined!);
export default UserContext;

export function useUser() {
  return useContext(UserContext);
}

export type UserProviderProps = {
  children: React.ReactNode;
  user: UserQueryType | null | undefined;
  restrictions: PickUserRestrictionType[] | null | undefined;
  userToken?: string;
};

export function UserProvider(props: UserProviderProps) {
  const { children, user: userProps, restrictions: restrictionsProps, userToken } = props;
  const [user, setUser] = useState(userProps?.user);
  const [isOpenLogoutDialog, setOpenLogoutDialog] = React.useState(false);
  const [error, setError] = useState<Error | null>(null);

  // we don't need the result, we just need to execute stuff asap (no,
  // useEffect wont do because it'll run later than useMemo).
  useMemo(() => {
    if (typeof document !== "undefined") {
      const token = userToken;
      if (token) {
        gcSetApiToken(token);
      } else {
        refreshAnonymousToken();
      }
    }
  }, [userToken]);

  const updateUser = useCallback((newUser: UserType) => {
    setUser(newUser);
  }, []);

  const refreshUserToken = useCallback(async (params = {} as RefreshAnonymousTokenParams) => {
    const { onRefreshed } = params;
    const userAuthToken = await refreshAnonymousToken()
      .then((data) => {
        setError(null);
        return data;
      })
      .catch((err) => {
        setError(err);
        return null;
      });
    onRefreshed?.();
    return userAuthToken;
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const openLogoutDialog = useCallback(() => {
    setOpenLogoutDialog(true);
  }, []);

  const logoutUser = useCallback(() => {
    setOpenLogoutDialog(false);
    logOut();
  }, []);
  const restrictions = useGetCurrentPageRestriction(restrictionsProps);

  const providerValue: UserContextValue = useMemo(
    () => ({
      isLoggedIn: !!user?.userId,
      data: user,
      updateUser,
      refreshUserToken,
      restrictions,
      openLogoutDialog,
      logoutUser,
    }),
    [user, updateUser, refreshUserToken, restrictions, openLogoutDialog, logoutUser],
  );

  return (
    <UserContext.Provider value={providerValue}>
      {children}
      {error && (
        <ErrorBoundaryPopup
          message={error.message}
          reloadTimer={(error.cause as keyof typeof errorMessage) in errorMessage}
          onClose={clearError}
        />
      )}
      <LogoutDialog isOpenLogoutDialog={isOpenLogoutDialog} setOpenLogoutDialog={setOpenLogoutDialog} logoutUser={logoutUser} />
    </UserContext.Provider>
  );
}
